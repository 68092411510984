"use client";

import JsonViewer from "@/components/dev/json-viewer";
import { getRandomInt } from "@/components/utils";
import useAppInfo from "@/modules/app/use-app-info";
import useCompanyUnit from "@/modules/company/company-unit/use-company-unit";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export default function HiddenDevPanel() {
  const router = useRouter();
  const [message, setMessage] = useState();
  const originals = useCompanyUnit((state) => state.originals); ///
  useEffect(() => {
    let t = undefined;
    function docKeyDown(e) {
      if ((e.code === "Digit0" && e.altKey) || e.code === "Escape") {
        window.__wait = 0;
        showMessage(window.__wait);
      } else if (e.code === "Digit1" && e.altKey) {
        window.__wait = 1000;
        showMessage(window.__wait);
      } else if (e.code === "Digit2" && e.altKey) {
        window.__wait = 2000;
        showMessage(window.__wait);
      } else if (e.code === "Digit3" && e.altKey) {
        window.__wait = 3000;
        showMessage(window.__wait);
      } else if (e.code === "Digit4" && e.altKey) {
        window.__wait = 4000;
        showMessage(window.__wait);
      } else if (e.code === "Digit5" && e.altKey) {
        window.__wait = 5000;
        showMessage(window.__wait);
      } else if (e.code === "KeyP" && e.altKey) {
        router.push("/playground");
      } else if (e.code === "KeyE" && e.altKey) {
        const err = window.__error ? false : true;
        window.__error = err;
        showMessage(err ? "err: ON" : "err: OFF");
      } else if (e.code === "KeyT" && e.altKey) {
      }
      function showMessage(msg) {
        setMessage(msg);
        clearTimeout(t);
        t = setTimeout(setMessage, 1000);
      }
    }
    document.addEventListener("keydown", docKeyDown);
    return () => document.removeEventListener("keydown", docKeyDown);
  }, []);

  return (
    <>
      <div className="fixed left-0 top-0 bg-gray-500 text-white">{message}</div>
      <div
        id="dev-view-panel"
        className="fixed left-0 top-0 bg-gray-500 text-white"
      >
        {/* {originals && <JsonViewer data={originals} />} */}
      </div>
    </>
  );
}

// function updateWait(wait) {
//   const request = new HttpRequest(process.env.NEXT_PUBLIC_HUBAPIADDRESS, "/Developer/SetWait/" + wait);
//   return request.post();
// }
